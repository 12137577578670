import { fromPairs } from "lodash";
import { KNOWN_ASSETS } from "@/config/asset";
import { unitsAreComparable } from "./number";
import { Unit, AssetConfig } from "@/types";

export function comparablePropertiesByKnownAsset(unit: Unit | null): Record<string, string[]> {
  if (!unit) return {};

  const pairs: [string, string[]][] = KNOWN_ASSETS.map(knownAsset => {
    const assetConfig = knownAsset.config.default;
    const properties = comparablePropertiesForAsset(assetConfig, unit);
    return [knownAsset.knownAssetUuid, properties];
  });
  return fromPairs(pairs);
}

function comparablePropertiesForAsset(assetConfig: AssetConfig, unit: Unit): string[] {
  const propertyNames = Object.keys(assetConfig.properties);
  return propertyNames.reduce((acc, name) => {
    const rawConfig = assetConfig.properties[name];
    if (rawConfig.comparable && rawConfig.unit && unitsAreComparable(rawConfig.unit, unit)) {
      acc.push(name);
    }
    return acc;
  }, [] as string[]);
}
